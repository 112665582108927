import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomePage')
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "about" */ '../views/Events')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy')
  },
  {
    path: '/privacy-app',
    name: 'privacy-app',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyApp')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView')
  },
  {
    path: '/need-help',
    name: 'need-help',
    component: () => import(/* webpackChunkName: "about" */ '../views/NeedHelp')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqView')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import(/* webpackChunkName: "about" */ '../views/Account/CreateAccount')
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account/ActivateAccount'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/unsub',
    name: 'unsub',
    component: () => import(/* webpackChunkName: "subinscricao" */ '../views/unsub.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
})

export default router
