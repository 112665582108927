import { axiosWithoutLoading } from '@/plugins/axios-instance';

export default {
  loading(context, value) {
    context.commit('setLoading', value)
  },
  setUrls(context) {
    axiosWithoutLoading({
      method: 'GET',
      url: 'site-urls'
    }).then(response => response.data)
      .then(data => {
        context.commit('setUrls', data)
      })
  },
  setVisitorId(context, value) {
    context.commit('setVisitorId', value);
    localStorage.setItem('zicket-visitorId', value)
  },
  setIsDesktop(context, value) {
    context.commit('setIsDesktop', value)
  },
  setMenuOpen(context, value) {
    context.commit("setActiveBackdrop", value);
    context.commit("setMenuOpen", value);
  },
  setLocale(context, value) {
    context.commit("setLocale", value);
    localStorage.setItem("zicket-locale", value);
  },
  toggleFormFixed(context) {
    if (context.getters.isDesktop) {
      context.commit("setActiveFormFloating", !context.getters.activeFormFloating);
      if (context.getters.activeFormFloating) {
        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-hidden");
        document
          .getElementsByClassName("backdrop_bar")[0]
          .classList.remove("d-none");
      } else {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("overflow-hidden");
        document
          .getElementsByClassName("backdrop_bar")[0]
          .classList.add("d-none");
      }
    } else {
      if (context.getters.activeMenuFooter) {
        context.commit("setActiveMenuFooter", false);
      } else {
        context.commit("setActiveMenuFooter", true);
      }
    }
  },
};